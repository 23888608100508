import React from 'react';
import styled, { css } from 'styled-components';
import { IDataItem } from '../../utils/types';
import folderIconPath from '../../assets/images/folder.png';
import { ReactComponent as ChevronRight } from '../../assets/images/chevron-right.svg';
import { isMobile } from 'react-device-detect';
import { moveMobileWindow } from './Window';

export const FolderString = ({
  item,
  active,
  onClick
}: {
  item: IDataItem,
  active: boolean,
  onClick(item: IDataItem): void;
}) => {
  const onClickHandler = () => {
    if(isMobile) {
      setTimeout(() => moveMobileWindow(1), 300)
    }
    onClick(item);
  }

  return <Container active={active} onClick={onClickHandler}>
    <FolderIconStyled src={folderIconPath} />
    <Name>{item.name}</Name>
    <ChevronRightStyled />
  </Container>
}

const Container = styled.div<{active: boolean}>`
  display: flex;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  gap: 10px;
  align-items: center;
  margin-bottom: 1px;

  @media (max-width:  600px) {
    margin-bottom: 10px;
  }

  :hover {
    background: var(--window-color-selected);
  }

  ${p => p.active && css`
    background: var(--window-color-selected);
  `}

`

const FolderIconStyled = styled.img`
  width: 20px;
`
const Name = styled.div`
  flex: 1;
`

const ChevronRightStyled = styled(ChevronRight)`
  width: 3px;
`
