import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { IDataItem } from '../../utils/types';
import { FolderContentGallery } from './FolderContentGallery';
import { FolderString } from './FolderString';
import { WindowTitleContext } from './Window';

export const FolderContentItem = ({
  folders,
  active
}: {
  folders: IDataItem[];
  active?: IDataItem;
}) => {
  const { setTitle } = useContext(WindowTitleContext);
  const [ current, setCurrent ] = useState<IDataItem | undefined>(active);

  const onClickFolder = (item: IDataItem) => {
    setTitle(item.name);
    setCurrent(item);
  }

  return <Container>
    <Column>
      {folders.map(folder => <FolderString 
        item={folder} 
        active={current?.id === folder.id} 
        onClick={onClickFolder}
        key={folder.id}
      />)}
    </Column>
    <Content>
      {current && current.items.length > 0 && <FolderContentItem folders={current.items} key={current.id || 'content'} />}
      {current && current.items.length === 0 && <FolderContentGallery item={current} key={current.id + 1 || 'gallery'} />}
    </Content>
  </Container>
}

const Container = styled.div`
  display: flex;
  width: 100%;

  @media (max-width:  600px) {
    width: auto;
  }
`

const Column = styled.div`
  width: 220px;
  padding: 20px 10px;
  border-right: 1px solid var(--window-color-border);

  @media (max-width:  600px) {
    min-width: calc(100vw - 36px);
  }
`

const Content = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;

  @media (max-width: 600px) {
    flex: initial;
  }
  
  ::-webkit-scrollbar {
    display: none;
  }
`
