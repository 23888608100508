
import { makeAutoObservable } from 'mobx';
import { depths } from '../hooks/useDrag';
import { getDesktopWindowPosition } from '../utils/helpers';
import { IDataItem, TDataItemType } from '../utils/types';

class WindowsStore {
  windows: IDataItem[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  getWindows() {
    return this.windows;
  }

  open(item: IDataItem, forceType?: TDataItemType) {
    let existWindow = this.windows.find(window => window.id === item.id);
    if(existWindow){
      //if(existWindow.data?.photoId !== item.data?.photoId) existWindow = item;
      existWindow.depth = depths.windows++;
      return;
    }

    const newWindow = {
      ...item,
      depth: depths.windows++,
      startPosition: getDesktopWindowPosition(item)
    }

    if(forceType) newWindow.type = forceType;

    this.windows.push(newWindow)
  }

  close(id: number) {
    this.windows = this.windows.filter(window => window.id !== id);
  }

  moveToFront(id: number) {
    const existWindow = this.windows.find(window => window.id === id);
    if(existWindow){
      existWindow.depth = depths.windows++;
      return;
    }
  }

  prevPhoto(item: IDataItem) {
    const index = item.media!.findIndex(photo => photo.id === item.data?.photoId);
    let newIndex = 0;
    if(!item.media || index < 0) return;
    
    if(index === 0) newIndex = item.media?.length - 1;
    else newIndex = index - 1;

    this.setPhoto(item, newIndex);

  }

  nextPhoto(item: IDataItem) {
    const index = item.media!.findIndex(photo => photo.id === item.data?.photoId);
    let newIndex = 0;
    if(!item.media || index < 0) return;
    
    if(index === item.media?.length - 1) newIndex = 0;
    else newIndex = index + 1;

    this.setPhoto(item, newIndex);
  }

  setPhoto(item: IDataItem, newPhotoIndex: number) {
    const window = this.windows.find(window => window.id === item.id);
    const photo = window?.media?.[newPhotoIndex];
    window!.data!.photoId! = photo?.id!;
  }

  
}

export const windowsStore = new WindowsStore();