import React from 'react';
import styled from 'styled-components';
import { iconsStore } from '../../store/Icons';
import { isFolder } from '../../utils/helpers';
import { IDataItem } from '../../utils/types';
import { FolderContentItem } from './FolderContentItem';

export const FolderContent = ({
  item
}: {
  item: IDataItem;
}) => {
  const allIcons = iconsStore.icons;
  const mainFolders = allIcons.filter(item => isFolder(item))

  return <Container id="folder-content">
    <FolderContentItem folders={mainFolders} active={item} />
  </Container>
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: calc(100vw - 80px);
  min-height: 500px;
  position: relative;

  @media (max-width:  600px) {
    width: calc(100vw - 36px);
    height: 100vh;
    overflow: hidden;
  }
`