import React, { memo, useEffect, useState } from 'react';
import dayjs from 'dayjs';

const HeaderClockComponent = () => {
  const [date, setDate] = useState(dayjs());
  useEffect(() => {
    const interval = setInterval(() => 
      setDate(dayjs())
    , 60000);
  }, []);

  const displayTime = date.format('ddd DD MMM HH:mm')
  
  return <>{displayTime}</>
}

export const HeaderClock = memo(HeaderClockComponent);