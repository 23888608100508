import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { IDataItem, IMedia } from '../../utils/types';
import { WindowTitleContext } from './Window';

export const GalleryContent = observer(({
  item
}: {
  item: IDataItem;
}) => {
  const { setTitle } = useContext(WindowTitleContext);
  const ref = useRef<HTMLImageElement>(null);
  const [ currentId, setCurrentId ] = useState(item.data!.photoId);
  const [ media, setMedia ] = useState<IMedia>();
  const [ orientation, setOrientation ] = useState<'gor' | 'ver'>('gor');
  
  const setOpacity = (opacity: number) => {
    ref.current && (ref.current.style.opacity = String(opacity));
  }

  useEffect(() => {
    media && setTitle(`${item.name} - ${media.name}`);
    setOpacity(0)
  }, [media])

  useEffect(() => {
    const media = item.media || [];
    setMedia(media.find(item => item.id === currentId));
  }, [currentId])

  useEffect(() => {
    setCurrentId(item.data!.photoId);
  }, [item.data!.photoId])

  const onLoadImage = () => {
    setOpacity(1);
    
    if((ref.current?.getBoundingClientRect().height || 0) > (ref.current?.getBoundingClientRect().width || 0)) {
      setOrientation('ver')
    } else {
      setOrientation('gor')
    }
  }

  if(!media) return null;   
  
  const orientation_ = +media.typeId === 2 ? 'gor' : orientation;

  return <Container orientation={orientation_}>
    {+media.typeId === 1 && <Image src={media.url} onLoad={onLoadImage} ref={ref} />}
    {+media.typeId === 2 && <Video poster={media.preview} controls>
      <source src={media.url} />
    </Video>}
    <Loading>Loading</Loading>
  </Container>
})

const Container = styled.div<{orientation: 'gor' | 'ver'}>`
  width: ${p => p.orientation === 'gor' ? '660px' : '420px'};
  position: relative;
  
  @media (max-width:  600px) {
    width: calc(100vw - 36px);
    display: flex;
    flex: initial;
  }

`

const Image = styled.img`
  width: 100%;
  display: block;
  transition: opacity 0.2s;
  opacity: 0;
  height: auto;


  @media (max-width:  600px) {
    align-self: center;
  }
`

const Video = styled.video`
  width: 100%;
  height: 100%;

  @media (max-width:  600px) {
    height: auto;
    align-self: center;
  }
`

const Loading = styled.div`
  position: absolute;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.4;
`