import { type } from 'os';
import React, { memo, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { IDataItem } from '../../utils/types';
import { CSSTransition } from 'react-transition-group';
import { isMobile } from 'react-device-detect';

export const GalleryIcon = memo(({
  iconPath,
  name,
  onSelected,
  onOpen,
  item,
  type,
  active
}: {
  iconPath: string;
  name: string;
  onSelected(): void;
  onOpen(item: IDataItem): void;
  item: IDataItem;
  type: 'file' | 'photo';
  active: boolean
}) => {
  const pictureRef = useRef<HTMLImageElement>(null);
  const [ loaded, setLoaded ] = useState(false);

  useEffect(() => {
    if(pictureRef.current) {
      var bgImg = new Image();
      bgImg.onload = function(){
        pictureRef.current!.style.backgroundImage = 'url(' + bgImg.src + ')';
        setLoaded(true);
      };
      bgImg.src = iconPath;
    }
  }, []);

  const onDoubleClick = () => {
    if(!isMobile) onOpen(item);
  }
  const onClick = () => {
    if(isMobile) onOpen(item);
    onSelected();
  }

  return <Container onDoubleClick={onDoubleClick} onClick={onClick} active={active}>
    <CSSTransition nodeRef={pictureRef} in={loaded} timeout={200} classNames="opacity-animate">
      <Picture 
        iconPath={iconPath} 
        type={type} 
        ref={pictureRef}
      />
    </CSSTransition>
    <Text>{name}</Text>
  </Container>
});

const Container = styled.div<{active: boolean}>`
  display: flex;
  width: 95px;
  align-items: center;
  flex-direction: column;
  gap: 6px;
  border-radius: 4px;
  padding: 15px 0;
  
  ${p => p.active && css`
    background: var(--window-color-selected);
  `}
`

const Picture = styled.div<{iconPath: string, type: string}>`
  height: 80px;
  width: 80px;
  background-size: ${p => p.type === 'file' ? '70%' : 'contain'};
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url('${p => p.iconPath}');
`

const Text = styled.div`
  text-align: center;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

`