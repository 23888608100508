import { TPosition } from "../hooks/useDrag";

export type TDataItemType = 'file' | 'folder' | 'photo' | 'link';

export type TDataItemData = {
  photoId: number;
}
export interface IDataItem {
  id: number;
  type: TDataItemType;
  name: string;
  startPosition: TPosition;
  items: any[];
  description: string;
  media?: IMedia[];
  link?: string;
  
  depth?: number;
  data?: TDataItemData;
}

export interface IMedia {
  id: number;
  preview: string;
  url: string;
  name: string;
  typeId: MediaType;
}

export const enum MediaType {
  Photo = 1,
  Video = 2,
  Pdf = 6
}