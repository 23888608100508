import React, { useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import logoImg from '../../assets/images/logo.png';
import { ReactComponent as Logo_ } from '../../assets/images/logo.svg'
import { Switcher } from '../ui/Switcher';
import { HeaderClock } from './HeaderClock';
import { configStore, THEME_DARK, THEME_LIGHT } from '../../store/Config';

export const Header = observer(() => {
  const theme = configStore.theme;
  const checked = theme === THEME_DARK;
  
  const onChangeTheme = () => {
    configStore.changeTheme(!checked ? THEME_DARK : THEME_LIGHT);
  }

  return <Container>
    <Logo />
    <RightBlock>
      <ClockContainer><HeaderClock /></ClockContainer>
      <div><Switcher checked={checked} onChange={onChangeTheme} /></div>
    </RightBlock>
  </Container>
})

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 0 40px 0 20px;

  @media (max-width:  600px) {
    padding: 0 18px 0 18px;
  }
`

const RightBlock = styled.div`
  display: flex;
  gap: 40px;
  align-items: center;
`

const Logo = styled(Logo_)`
  width: 78px;
  display: inline-block;
  margin-top: 1px;
`

const ClockContainer = styled.div`
  @media (max-width:600px) {
    display: none;
  }
`