import React from 'react';
import { IDataItem } from '../../utils/types';
import { Icon } from './Icon';

export const Folder = ({
  item
}: {
  item: IDataItem
}) => {
  return <Icon type="folder" item={item} />
}