import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import folderIconPath from '../../assets/images/folder.png'
import fileIconPath from '../../assets/images/file.png'
import { useDrag } from '../../hooks/useDrag';
import { IDataItem } from '../../utils/types';
import { windowsStore } from '../../store/Windows';
import { isMobile } from 'react-device-detect';



export const Icon = memo(({
  type = 'folder',
  item,
}: {
  type: 'folder' | 'file' | 'media',
  item: IDataItem,
}) => {
  const { ref, onMouseDown, onMouseMove} = useDrag('icons', item);
  
  const icon = type === 'folder' ? folderIconPath : fileIconPath;

  const openWindow = () => {
    if(type === 'media') {
      windowsStore.open({
        ...item,
        data: { photoId: item.media![0].id }
      }, 'photo');
    } else {
      windowsStore.open(item);
    }
    
  }

  const onOpenDesktop = useCallback(() => {
    if(isMobile) return;
    openWindow();
  }, []);
  const onOpenMobile = useCallback(() => {
    if(!isMobile) return;
    openWindow();
  }, []);

  return <Container
    ref={ref}
    onMouseDown={onMouseDown}
    onMouseMove={onMouseMove}
    onDoubleClick={onOpenDesktop}
    onClick={onOpenMobile}
    depth={item.depth}
  >
    {type !== 'media' && <Image icon={icon}></Image>}
    {type === 'media' && <Picture 
      iconPath={item.media![0].preview} 
      type={type} 
    />}
    <Title><span>{item.name}</span></Title>
  </Container>
})

const Container = styled.div<{depth?: number}>`
  width: 60px;
  user-select: none;
  position: absolute;
  cursor: pointer;

  z-index: ${p => p.depth || 0};

  :active {
    cursor: grabbing;
  }

`

const Image = styled.div<{icon: string}>`
  height: 47px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url('${p => p.icon}');
`

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  /* font-family: 'avenir_nextregular'; */

  > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const Picture = styled.div<{iconPath: string, type: string}>`
  width: 100%;
  aspect-ratio: 1/1;
  background-size: ${p => p.type === 'file' ? '70%' : 'contain'};
  background-position: center bottom;
  background-repeat: no-repeat;
  background-image: url('${p => p.iconPath}');
`