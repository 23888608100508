import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import dayjs from 'dayjs';
import { configStore } from '../../store/Config';

export const Footer = observer(() => {
  const footerMenuItems = configStore.footerMenuItems.filter(item => item.type === 'link');
  const year = dayjs().format('YYYY')
  return <Container>
    <div>
      © {year} Kasatka Studio 
      <Copyright> x <a href="https://www.createdbyotomweb.com" target="_blank" rel="noreferrer">Created by Otomweb</a></Copyright>
    </div>
    <RightBlock>
      {footerMenuItems.map(item => <a key={item.id} href={item.link} target="_blank" rel="noreferrer">{item.name}</a>)}
    </RightBlock>
  </Container>
})

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 0 40px 0 20px;

  @media (max-width:  600px) {
    padding: 0 18px 0 18px;
  }
`

const RightBlock = styled.div`
  display: flex;
  gap: 40px;

  @media (max-width:  600px) {
    > a:nth-child(n + 3) {
      display: none;
    }
  }
`

const Copyright = styled.span`
  @media (max-width:  600px) {
    display: none;
  }
`