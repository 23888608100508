import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { IDataItem, MediaType } from '../../utils/types';
import { GalleryIcon } from './GalleryIcon';
import fileIconPath from '../../assets/images/file.png'
import { windowsStore } from '../../store/Windows';

export const FolderContentGallery = ({
  item
}: {
  item: IDataItem
}) => {
  const [ activeId, setActiveId ] = useState<number>();

  const onOpenAbout = useCallback((item: IDataItem) => {
    windowsStore.close(item.id);
    windowsStore.open(item, 'file');
  }, []);

  const onOpenGallery = useCallback((item: IDataItem, photoId: number) => {
    windowsStore.close(item.id);
    windowsStore.open({
      ...item,
      data: { photoId }
    }, 'photo');
  }, []);

  const onOpenPdf = useCallback((item: IDataItem, photoId: number) => {
    const link = document.createElement('a');
    link.href = item.media?.find(photo => +photo.id === +photoId)!.url!;
    link.target="_blank";
    link.click();
  }, []);
  
  return <Container>
    {item.description.length > 5 && 
      <GalleryIcon 
        iconPath={fileIconPath} 
        name="About" 
        key={-1} 
        onSelected={() => setActiveId(0)} 
        onOpen={onOpenAbout} 
        item={item} 
        type="file" 
        active={activeId === -1} 
      />
    }
    {item.media && item.media.map(photo => {
      if(+photo.typeId === MediaType.Pdf) {
        return <GalleryIcon 
          iconPath={fileIconPath} 
          name="View pdf" 
          key={-2} 
          onSelected={() => setActiveId(-2)} 
          onOpen={item => onOpenPdf(item, photo.id)} 
          item={item} 
          type="file" 
          active={activeId === -2} 
        />
      } else {
        return <GalleryIcon 
          iconPath={photo.preview} 
          name={photo.name} 
          key={photo.id} 
          onSelected={() => setActiveId(photo.id)} 
          onOpen={item => onOpenGallery(item, photo.id)} 
          item={item} 
          type="photo" 
          active={activeId === photo.id} 
        />
      }
    }
      
    )}
  </Container>
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 20px 10px;
  gap: 15px 25px;
  height: min-content;

  @media (max-width:  600px) {
    min-width: calc(100vw - 36px);
    padding-bottom: 50px;
  }
`
