import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Footer } from './Footer';
import { Header } from './Header';

export const Main = ({
  children
}: {
  children: ReactNode
}) => {
  return <Container>
    <Header_><Header /></Header_>
    <Content_>{children}</Content_>
    <Footer_><Footer /></Footer_>
  </Container>
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const Header_ = styled.div`
  height: 46px;
`

const Content_ = styled.div`
  flex: 1;
`

const Footer_ = styled.div`
  height: 46px;
`