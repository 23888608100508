
import { makeAutoObservable } from 'mobx';
import { depths } from '../hooks/useDrag';
import { API_URL } from '../utils/api';
import { getDesktopIconPosition } from '../utils/helpers';
import { IDataItem } from '../utils/types';


class IconsStore {
  icons: IDataItem[] = [];
  loaded = false;

  constructor() {
    makeAutoObservable(this);
    this.fetchIcons();
  }

  getIcons() {
    return this.icons;
  }

  fetchIcons() {
    setTimeout(() => {   
      fetch(`${API_URL}/Home/desktop`)
        .then(data => data.json())
        .then(data => {
          const data2 = data.map((item: IDataItem, index: number) => ({
            ...item,
            startPosition: getDesktopIconPosition(item, data.length - index - 1)
          }))
          console.log(data2);
          this.icons = data2;
          this.loaded = true;
        })
      }, 500);
  }

  moveToFront(id: number) {
    const existIcon = this.icons.find(icon => icon.id === id);
    if(existIcon){
      existIcon.depth = depths.icons++;
      return;
    }
  }

  
}

export const iconsStore = new IconsStore();