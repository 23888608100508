import { IDataItem } from "./types";
import { isMobile } from "react-device-detect";

export const isFolder = (item: IDataItem) => item.items.length > 0;
export const isFile = (item: IDataItem) => item.type === 'file';

export const getDesktopIconPosition = (item: IDataItem, index: number) => {
  if(isMobile) {
    const inRow = Math.ceil((window.innerWidth - 40 - 40) / 120);
    const x = 40 + 120 * (index % inRow);
    const y = 40 + 140 * Math.floor(index / inRow);
    return {
      right: x,
      left: undefined,
      top: undefined,
      bottom: y
    }
  } else {
    return {
      right: +item.startPosition.right! ? +item.startPosition.right! : undefined,
      left: +item.startPosition.left! ? +item.startPosition.left! : undefined,
      top: +item.startPosition.top! ? +item.startPosition.top! : undefined,
      bottom: +item.startPosition.bottom! ? +item.startPosition.bottom! : undefined
    }
  }
}

export const getDesktopWindowPosition = (item: IDataItem) => {
  if(isMobile) {
    return {
      left: 18,
      top: 15
    }
  } else {
    return {left: 40, top: 40}
  }
}