import React from 'react';
import styled, { css } from 'styled-components';

export const Switcher = ({
  checked = false,
  onChange
}: {
  checked: boolean,
  onChange: (checked: boolean) => void
}) => {
  return <Container onClick={() => onChange(!checked)}>
    <Circle checked={checked} />
  </Container>;
}

const Container = styled.div`
  width: 30px;
  height: 15px;
  border: 1px solid var(--ui-color-border);
  background-color:  var(--ui-color-background);
  border-radius: 10px;
  position: relative;
  cursor: pointer;
`

const Circle = styled.div<{checked: boolean}>`
  width: 9px;
  height: 9px;
  position: absolute;
  top: 2px;
  border-radius: 50%;
  background-color:  var(--ui-color-circle);

  ${p => !p.checked && css`
    left: 3px;
  `}
  ${p => p.checked && css`
    right: 3px;
  `}
`