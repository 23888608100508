
import { makeAutoObservable } from 'mobx';
import { API_URL } from '../utils/api';
import { IDataItem, IMedia } from '../utils/types';

export const THEME_DARK = 'dark';
export const THEME_LIGHT = 'light';
export type ITheme = typeof THEME_DARK | typeof THEME_LIGHT;

class ConfigStore {
  theme: ITheme = 'light';
  footerMenuItems: IDataItem[] = [];
  backgroundItems: IMedia[] = [];
  loaded = false;

  constructor() {
    makeAutoObservable(this);
    this.fetchFooterMenuItems();
  }

  changeTheme(theme: ITheme) {
    this.theme = theme;
    document.body.dataset.theme = theme;
  }

  fetchFooterMenuItems() {
    setTimeout(() => {     
      const footerItemsP = fetch(`${API_URL}/Home/footer`)
        .then(data => data.json());
      const backgroundItemsP = fetch(`${API_URL}/Home/background`)
        .then(data => data.json());
      Promise.all([footerItemsP, backgroundItemsP])
        .then(([footerItems, backgroundItems]) => {
          this.footerMenuItems = footerItems;
          this.backgroundItems = backgroundItems;
          this.loaded = true;
        })
      }, 750);
  }
}

export const configStore = new ConfigStore();