import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { Desktop } from './components/desktop/Desktop';
import { Main } from './components/layout/Main';
import { configStore } from './store/Config';
import { iconsStore } from './store/Icons';
import styled from 'styled-components';

const App = observer(() => {
  const footerMenuLoaded = configStore.loaded;
  const iconsLoaded = iconsStore.loaded;
  const backgroundItems = configStore.backgroundItems;
  
  const nodeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if(iconsLoaded && footerMenuLoaded) {
      const background = backgroundItems[0];
      
      setTimeout(() => {
        if(background) {
          let bgImg = new Image();
          bgImg.onload = function(){
            nodeRef.current!.style.backgroundImage = `url(${bgImg.src})`;
            nodeRef.current!.style.opacity = '1';
          };
          bgImg.src = background.url;
          
        } else {
          nodeRef.current!.style.opacity = '1';
        }
      }, 500);
      
    }
  }, [footerMenuLoaded, iconsLoaded, backgroundItems])

  return <Animate ref={nodeRef}>
      <Main>
        <Desktop />
      </Main>
  </Animate>
})

const Animate = styled.div`
  width: 100%;
  height: 100%;
  background: var(--background-color);
  background-size: cover;
  background-position: center center;
  opacity: 0;
  transition: opacity 0.5s;
`

export default App;
