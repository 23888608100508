import React, { memo } from 'react';
import styled from 'styled-components';
import { Folder } from './Folder';
import { File } from './File';
import { Media } from './Media';
import { Window } from '../window/Window';
import { observer } from 'mobx-react-lite';
import { windowsStore } from '../../store/Windows';
import { iconsStore } from '../../store/Icons';
import { TextContent } from '../window/TextContent';
import { GalleryContent } from '../window/GalleryContent';
import { FolderContent } from '../window/FolderContent';
import { isFile, isFolder } from '../../utils/helpers';

const Contents = {
  'file': TextContent,
  'folder': FolderContent,
  'photo': GalleryContent
}

export const Desktop = observer(() => {
  const icons = iconsStore.icons;
  const windows = windowsStore.windows;

  return <Container>
      {icons.map(icon => {
        const Item = isFolder(icon) ? Folder : (isFile(icon) ? File : Media);
        return <Item item={icon} key={icon.id} />
      })}

      {windows.map(window => {
          if (window.type === 'link') return null;
          let ContentComponent = Contents[window.type];

          return <Window item={window} key={window.id}>
            <ContentComponent item={window} />
          </Window>
        }
      )}
      
  </Container>
})

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

const Icons = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
`

const Windows = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
`