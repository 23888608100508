import { RefObject, useEffect, useRef, useState } from "react";
import { iconsStore } from "../store/Icons";
import { windowsStore } from "../store/Windows";
import { IDataItem } from "../utils/types";
import { isMobile } from "react-device-detect";

export let depths = {
  icons: 0,
  windows: 10000
};

export type TPosition = {
  right?: number;
  left?: number;
  top?: number;
  bottom?: number;
}

export const useDrag = (
  depthKey: keyof typeof depths,
  item: IDataItem
) => {
  const ref = useRef<HTMLDivElement>(null);
  const [pressed, setPressed] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const start = item.startPosition;

  const changeZIndex = () => {
    if(depthKey === 'windows') {
      windowsStore.moveToFront(item.id);
    } else if(depthKey === 'icons') {
      iconsStore.moveToFront(item.id);
    }
    //ref.current!.style.zIndex = String(depths[depthKey]++); // FIXME: 
  }

  useEffect(() => {
    const stopDrag = () => {
      ref.current!.style.opacity = '1';
      setPressed(false);
    }
    document.addEventListener('mouseup', stopDrag);
    return () => {
      document.removeEventListener('mouseup', stopDrag);
    }
  }, []);

  useEffect(() => {
    if (ref.current) {
      if(Number.isFinite(start.right)) {
        ref.current.style.right = `${start.right}px`;
      } else if(Number.isFinite(start.left)) {
        ref.current.style.left = `${start.left}px`;
      }

      if(Number.isFinite(start.top)) {
        ref.current.style.top = `${start.top}px`;
      } else if(Number.isFinite(start.bottom)) {
        ref.current.style.bottom = `${start.bottom}px`;
      }

      ref.current.addEventListener('click', changeZIndex);
    }
  }, [ref.current]);

  useEffect(() => {
    if (ref.current) {
      ref.current.style.transform = `translate(${position.x}px, ${position.y}px)`;
    }
  }, [position]);

  const onMouseMove = (event: React.MouseEvent) => {
    if (pressed) {
      setPosition({
        x: position.x + event.movementX,
        y: position.y + event.movementY,
      });
    }
  };

  const onMouseDown = () => {
    setPressed(true);
    ref.current!.style.opacity = '0.75';
    changeZIndex();
  }

  if(isMobile) {
    return {
      ref,
      onMouseMove: () => {},
      onMouseDown: () => {}
    }
  }

  return {
    ref,
    onMouseMove,
    onMouseDown
  }

}