import React from 'react';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '../../assets/images/folder-close.svg'

export const CloseButton = ({
  onClose
}: {
  onClose(): void;
}) => {
  return <Container onClick={onClose}>
    <Circle><CloseIcon /></Circle>
  </Container>
}

const Container = styled.div`
  padding: 5px;
`

const Circle = styled.div`
  width: 12px;
  height: 12px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--color-red);
  border-radius: 50%;
  cursor: pointer;

  > svg {
    width: 55%;
    height: 55%;
    opacity: 0;
    transition: opacity 0.2s;
  }

  :hover {
    > svg {
      opacity: 1;
    }
  }
`