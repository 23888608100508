import React from 'react';
import styled from 'styled-components';
import { IDataItem } from '../../utils/types';

export const TextContent = ({
  item
}: {
  item: IDataItem;
}) => {
  return <Container>
    <Overflow>
      <span dangerouslySetInnerHTML={{__html: item.description}} />
    </Overflow>
  </Container>
}

const Container = styled.div`
  width: 540px;
  padding: 50px 20px;

  /* font-family: 'avenir_nextregular'; */
  font-size: 14px;
  line-height: 24px;

  display: flex;
  flex: 1;
  position: relative;

  @media (max-width:  600px) {
    width: calc(100vw - 36px);
    flex: initial;
  }

`

const Overflow = styled.div`
  overflow: auto;
  
  ::-webkit-scrollbar {
    display: none;
  }
`