import { observer } from 'mobx-react-lite';
import React, { ReactNode, useState } from 'react';
import styled from 'styled-components';
import { TPosition, useDrag } from '../../hooks/useDrag';
import { windowsStore } from '../../store/Windows';
import { IDataItem } from '../../utils/types';
import { CloseButton } from '../ui/CloseButton';
import { ReactComponent as ArrowLeftIcon } from '../../assets/images/arrow-left.svg'
import { ReactComponent as ArrowRightIcon } from '../../assets/images/arrow-right.svg'
import { isMobile } from 'react-device-detect';

export const moveMobileWindow = (direction: 1 | -1) => {
  const el = document.querySelector('#folder-content') as HTMLDivElement;
  const arrows = document.querySelector('#folder-arrows') as HTMLDivElement;
  el.scrollLeft += direction * (window.innerWidth - 36);

  if(el.scrollLeft > 0) arrows.style.display = 'block';
  else arrows.style.display = 'none';
}

export const WindowTitleContext = React.createContext({
  setTitle: (title: string) => {}
});

export const Window = observer(({
  item,
  children
}: {
  item: IDataItem;
  children: ReactNode
}) => {
  const [title, setTitle] = useState(item.name);
  const { ref, onMouseDown, onMouseMove } = useDrag('windows', item);

  const onClose = () => {
    windowsStore.close(item.id);
  }

  const onMobileBack = () => {
    moveMobileWindow(-1);
  }

  const isMobileWindow = !item.data?.photoId && isMobile && item.type === 'folder';
  
  const mediaCount = (item.media && item.media.length) || 0;
  
  return <Container ref={ref} id={`window-${item.id}`} depth={item.depth}>
    <WindowTitleContext.Provider value={{setTitle}}>

      <Header onMouseDown={onMouseDown} onMouseMove={onMouseMove}>
        <CloseButton onClose={onClose} />
        <span>{title}</span>

        {isMobileWindow && <Arrows id="folder-arrows">
          <ArrowLeftIcon onClick={onMobileBack} onMouseDown={e => e.stopPropagation()} />
        </Arrows>}
        

        {item.data?.photoId && mediaCount > 1 && <Arrows>
          <ArrowLeftIcon onClick={() => windowsStore.prevPhoto(item)} onMouseDown={e => e.stopPropagation()} />
          <ArrowRightIcon onClick={() => windowsStore.nextPhoto(item)} onMouseDown={e => e.stopPropagation()} />
        </Arrows>}

      </Header>

      <Content>{children}</Content>
    </WindowTitleContext.Provider>
  </Container>;
})

const Container = styled.div<{depth?: number}>`
  min-height: 300px;
  max-height: calc(100vh - 160px);
  position: absolute;
  display: flex;
  flex-direction: column;

  background-color: var(--window-color-background);
  border: 1px solid var(--window-color-border);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  z-index: ${p => p.depth || 0};

  @media (max-width:  600px) {
    max-height: initial;
    left: 16px !important;
    top: 15px !important;
    right: 16px !important;
    bottom: 25px !important;
  }
`

const Header = styled.div`
  height: 36px;
  min-height: 36px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  background: var(--window-color-header);
  gap: 5px;
  cursor: grab;

  :active {
    cursor: grabbing;
  }
`

const Arrows = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 20px;

  > svg {
    width: 20px;
    cursor: pointer;
  }

  &#folder-arrows {
    display: none;
  }
`

const Content = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
`